





















































import { mapActions, mapGetters, mapState } from 'vuex';
import PaymentsForm from '@/views/PaymentsView/PaymentsForm.vue';
import PaymentsLimitsUsersTable from '@/views/PaymentsView/PaymentsLimitsUsersTable.vue';
import Tooltip from '@/components/tooltip/Tooltip.vue';
import VTabsHash from '@/components/VTabsHash.vue';
import { kebabCase } from 'lodash';
import { toTitleCase } from '@/helpers/formatString';

import { TTabsItem } from '@/api/schema';

export default {
  name: 'PaymentsView',
  components: {
    PaymentsForm,
    Tooltip,
    PaymentsLimitsUsersTable,
    VTabsHash
  },
  data(): any {
    return {
      tab: 0,
      tooltipText: `Users can earn tokens only in Play to Earn and Hold to Earn programs.
<br/>
<br/>
Deposit can be made after the first withdrawal. Max deposit amount can't
excceed the amount of withdrawed tokens.`
    };
  },
  computed: {
    ...mapState('Payments', ['disableTokenSale']),
    ...mapGetters('ClientsInfo', ['isListed']),
    ...mapGetters('Onboarding', ['operatorId']),
    ...mapGetters('Payments', ['paymentTabs', 'paymentTabsItems']),
    ...mapState('FeatureFlags', ['features']),
    ...mapState('app', ['isSuperAdmin']),
    ...mapState('ClientsInfo', ['loadingInfo']),

    tabs(): TTabsItem[] {
      return this.paymentTabs.map((tab: string) => ({
        id: kebabCase(tab),
        text: toTitleCase(this.humanType(tab))
      }));
    },
    paymentLimitsDisabled(): boolean {
      return (
        this.features.find((v) => v.name === 'payment_limits')?.enabled ===
        false
      );
    },
    showLimitTable(): boolean {
      return this.paymentTabs[this.tab].includes('OPERATOR');
    }
  },
  watch: {
    operatorId: {
      handler(newId: number | null): void {
        if (newId) {
          this.getPaymentMethods();
        }
      },
      immediate: true
    },
    loadingInfo: {
      handler(load: boolean): void {
        if (!load) {
          this.superAdminPermissionHandler(this.isListed);
        }
      },
      immediate: true
    },
    paymentLimitsDisabled: {
      handler(status: boolean): void {
        this.superAdminPermissionHandler(!status);
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('Payments', [
      'getPaymentMethods',
      'enableDynamicDepositLimit'
    ]),
    humanType(type: string): string {
      return type.split('_').join(' ');
    },
    async toggleDynamicDepositLimit(enabled: boolean): Promise<void> {
      try {
        await this.enableDynamicDepositLimit(enabled);
        this.$toast.success(
          `Tokens are ${enabled ? 'disallowed' : 'allowed'} to sale`
        );
      } catch (err) {
        this.$toast.error(`Something went wrong! Try again later`);
      }
    },
    superAdminPermissionHandler(status: boolean | number): void {
      if (!status && !this.isSuperAdmin) {
        this.$router.push('/');
      }
    }
  }
};
