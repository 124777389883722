






















































import { PropType } from 'vue';
import { mapGetters } from 'vuex';
import { getUsersPaymentLimits } from '@/api/Payments';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { capitalization } from '@/helpers/formatString';
import type { AxiosError } from 'axios';
import type { PaymentsLimitUsersResponse } from '@/api/schema';
import { deleteUserTransactionLimits } from '@/api/ClientInfo';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import { PaymentsLimitUserData, PaymentMethodTypeEnum } from '@/api/schema';

const defaultOptions = {
  page: 1,
  itemsPerPage: 10
};

export default {
  name: 'PaymentsLimitsUsersTable',
  props: {
    type: {
      type: String as PropType<PaymentMethodTypeEnum>,
      default: false
    }
  },
  components: {
    ConfirmationDialog
  },
  data: (): any => ({
    options: { ...defaultOptions },
    loading: false,
    usersData: [],
    total: 0,
    headers: [
      {
        text: 'Operator User ID',
        value: 'operatorUserId'
      },
      {
        text: 'Max Transaction Amount',
        value: 'maximumTransactionAmount'
      },
      {
        text: 'Action',
        value: 'actions',
        align: 'right'
      }
    ],
    processing: false,
    operatorUserId: null,
    confirmationDialog: false,
    confirmationDialogData: null
  }),
  computed: {
    ...mapGetters('Onboarding', ['operatorId']),
    currentPage(): number {
      return this.options.page - 1;
    },
    generalType(): string {
      return this.type.includes('DEPOSIT') ? 'Deposit' : 'Withdraw';
    },
    headersForRole(): any {
      return this.$role.can.delete('payments')
        ? this.headers
        : [this.headers[0], this.headers[1]];
    },
    requestType(): string {
      return this.type.includes('DEPOSIT')
        ? PaymentMethodTypeEnum.OPERATOR_DEPOSIT
        : PaymentMethodTypeEnum.OPERATOR_WITHDRAW;
    }
  },
  watch: {
    options: {
      async handler(): Promise<void> {
        await this.updateUsersList();
      },
      deep: true
    },
    operatorId(newVal: number | null): void {
      if (newVal) this.options = { ...defaultOptions };
    },
    type: 'updateUsersList'
  },
  methods: {
    capitalization,
    updateUsersList(): void {
      if (!this.operatorId) return;

      this.loading = true;
      getUsersPaymentLimits({
        type: this.requestType,
        page: this.currentPage,
        size: this.options.itemsPerPage,
        operatorUserId: this.operatorUserId
      })
        .then((data: PaymentsLimitUsersResponse): void => {
          const { totalElements, content } = data;
          this.total = totalElements;
          this.usersData = content;
        })
        .catch((error: AxiosError): void => {
          errorToastMessage(error);
        })
        .finally((): void => {
          this.loading = false;
        });
    },
    async deleteCurrentLimit(id: string): Promise<void> {
      this.processing = true;
      try {
        await deleteUserTransactionLimits(id, this.requestType);
        await this.updateUsersList();
        this.$toast.success(
          `${this.generalType} limit for user are successfully cleared!`
        );
      } catch (err) {
        errorToastMessage(err);
      } finally {
        this.processing = false;
      }
    },
    showConfirmationDialog(userData: PaymentsLimitUserData): void {
      this.confirmationDialogData = {
        handler: this.deleteCurrentLimit.bind(this, userData.userId),
        header: 'Delete limit',
        disclaimer: `Are you sure to delete individual ${this.generalType.toLowerCase()} limit for user ${
          userData.operatorUserId
        }? Standard ${this.generalType.toLowerCase()} limits will be applied.`,
        okText: 'Delete'
      };
      this.$nextTick((): void => {
        this.confirmationDialog = true;
      });
    },
    closeConfirmationDialog(): void {
      if (this.processing) return;
      this.confirmationDialog = false;
      this.confirmationDialogData = null;
    },
    confirmationDialogHandler(): void {
      const { handler } = this.confirmationDialogData;
      handler().then((): void => {
        this.closeConfirmationDialog();
      });
    }
  }
};
