




























































































































import { PropType } from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import * as vr from '@/helpers/validation';
import { capitalization } from '@/helpers/formatString';
import Tooltip from '@/components/tooltip/Tooltip.vue';
import ExternalLink from '@/components/links/ExternalLink.vue';
import type { PaymentMethod } from '@/api/schema';

export default {
  name: 'PaymentsForm',
  components: {
    Tooltip,
    ExternalLink
  },
  props: {
    items: {
      type: Array as PropType<PaymentMethod[]>,
      required: true
    }
  },
  data(): any {
    return {
      formValid: true,
      selectedLimitType: null,
      formData: {
        fee: null,
        type: ''
      }
    };
  },
  computed: {
    ...mapState('Auth', ['tokenSymbol']),
    ...mapState('Payments', ['loading', 'disableTokenSale']),
    ...mapState('app', ['isSuperAdmin']),
    ...mapState('ClientsInfo', ['clientsInfo']),
    ...mapGetters('ClientsInfo', ['isListed']),
    limitName(): string {
      const type = this.formData?.type;
      return type
        .split('_')
        .slice(0, 2)
        .map(
          (word: string): string =>
            word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(' ');
    },
    toggleValue(): boolean {
      return this.formData.active;
    },
    fee: {
      get(): number | null {
        return this.formData?.fee * 100;
      },
      set(value: number): void {
        this.formData.fee = value / 100;
      }
    },
    isDeposit(): boolean {
      return this.formData?.type?.includes('_DEPOSIT');
    },
    isCrypto(): boolean {
      return this.formData?.type?.includes('CRYPTO');
    },
    featureAvailableStatus(): string {
      const methodType = this.formData?.type?.split('_')[1] || '';
      const methodName =
        methodType === 'DEPOSIT' ? capitalization(methodType) : 'Withdrawal';
      return `${methodName} ${this.formData.active ? 'On' : 'Off'}`;
    },
    isLoading(): boolean {
      return this.loading?.methods;
    },
    rules(): any {
      const minMaxIsOk =
        +this.formData.maximumTransactionAmount >=
        +this.formData.minimumTransactionAmount;

      return {
        fee: [vr.required, vr.validNumber, vr.gte, (v) => vr.lte(v, 100)],
        minimumTransactionAmount: [
          (v) => vr.required(v),
          (v) => vr.validNumber(v),
          (v) => vr.positiveNumber(v),
          minMaxIsOk
            ? true
            : (v) => {
                return (
                  +v <= +this.formData.maximumTransactionAmount ||
                  'Minimum amount should be lower or equal than maximum amount!'
                );
              }
        ],
        maximumTransactionAmount: [
          (v) => vr.required(v),
          (v) => vr.validNumber(v),
          (v) => vr.positiveNumber(v),
          minMaxIsOk
            ? true
            : (v) => {
                return (
                  +v >= +this.formData.minimumTransactionAmount ||
                  'Maximum amount should be higher or equal than minimum amount!'
                );
              }
        ]
      };
    }
  },
  watch: {
    items: {
      handler(newValue: PaymentMethod[] | null): void {
        this.$refs.form?.resetValidation();
        const paymentMethod =
          newValue.find((item) => this.selectedLimitType === item?.type) ||
          newValue[0];

        this.formData = newValue ? { ...paymentMethod } : null;

        if (paymentMethod) {
          this.selectedLimitType = paymentMethod?.type;
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...mapActions('Payments', [
      'updatePaymentMethod',
      'updatePaymentMethodStatus'
    ]),
    getHumanLimitTypeName(item: PaymentMethod): string {
      const parts = item?.type.split('_');
      return parts[parts.length - 1];
    },
    selectLimitType(index: number): void {
      this.selectedLimitType = this.items[index]?.type || null;

      this.$nextTick((): void => {
        this.formData = this.items[index];
        this.$refs.form?.resetValidation();
      });
    },
    buttonClasses(item: PaymentMethod): string[] {
      return [
        item?.type === this.selectedLimitType ? 'active' : '',
        this.getHumanLimitTypeName(item).toLowerCase()
      ];
    },
    toggleActive(): void {
      if (this.isLoading) {
        return;
      }
      const payload = this.preparePayload();

      this.$refs.form?.validate();
      this.$nextTick((): void => {
        if (!this.formValid) {
          this.$toast.error(`Limits should be filled before this action`);

          return;
        }

        this.updatePaymentMethodStatus({
          ...payload,
          active: !this.formData.active
        }).then((): void => {
          this.$toast.success(
            `${this.limitName} has been turned ${
              this.formData.active ? 'on' : 'off'
            }`
          );
        });
      });
    },
    preparePayload(): PaymentMethod {
      return { ...this.formData };
    },
    submit(): any {
      this.$refs.form?.validate();
      this.$nextTick((): void => {
        if (this.formValid) {
          let payload = this.preparePayload();

          return this.updatePaymentMethod(payload)
            .then(() => {
              this.$toast.success(
                `${this.limitName} limits has been updated`
              );
            })
            .catch(errorToastMessage);
        }
      });
    }
  }
};
